import React from "react";

/**
 * Black and White version of the BindPlaneOP Logo.
 */
export const BindPlaneOPLogo: React.FC<React.ComponentProps<"svg">> = (
  props
) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1249 317"
      {...props}
    >
      <rect fill="none" x="-4" y="18" width="1054" height="317" />
      <rect
        fill="#fff"
        x="1053.02249"
        y="46"
        width="192"
        height="192"
        rx="25"
        ry="25"
      />
      <path
        fill="#69b6e3"
        d="M1149.40756,113.455c0,8.231-1.5,15.30699-4.51001,21.23-3.01001,5.897-7.14001,10.439-12.39001,13.62601-5.21997,3.162-11.15002,4.743-17.80005,4.743-6.67993,0-12.64001-1.58099-17.88-4.743-5.21997-3.18701-9.33997-7.742-12.34998-13.664s-4.51001-12.98601-4.51001-21.192c0-8.232,1.5-15.29601,4.51001-21.193,3.01001-5.9223,7.13-10.4644,12.34998-13.6263,5.23999-3.1871,11.20007-4.78059,17.88-4.78059,6.65002,0,12.58008,1.5935,17.80005,4.78059,5.25,3.1619,9.38,7.704,12.39001,13.6263,3.01001,5.897,4.51001,12.961,4.51001,21.193Zm-11.51001,0c0-6.274-1.02002-11.557-3.05005-15.848-2.01001-4.316-4.77002-7.578-8.28003-9.78671-3.48999-2.2334-7.43994-3.3501-11.85999-3.3501-4.43994,0-8.41003,1.1167-11.90002,3.3501-3.47998,2.2087-6.23999,5.4707-8.27991,9.78671-2.01001,4.291-3.01001,9.574-3.01001,15.848,0,6.27299,1,11.56799,3.01001,15.884,2.03992,4.29199,4.79993,7.554,8.27991,9.787,3.48999,2.209,7.46008,3.31299,11.90002,3.31299,4.42004,0,8.37-1.10399,11.85999-3.31299,3.51001-2.233,6.27002-5.49501,8.28003-9.787,2.03003-4.31601,3.05005-9.61101,3.05005-15.884Zm26.0199,38.545V74.9091h27.47009c6,0,10.96997,1.0916,14.90991,3.2748,3.94006,2.1833,6.89001,5.1696,8.8501,8.9589,1.94995,3.7642,2.92993,8.0052,2.92993,12.7232,0,4.743-.98999,9.009-2.96997,12.798-1.95996,3.76399-4.92004,6.75099-8.88,8.959-3.93994,2.183-8.90002,3.27499-14.87,3.27499h-18.90002v-9.86199h17.83997c3.79004,0,6.87-.653,9.2301-1.958,2.34998-1.33,4.08997-3.137,5.18994-5.42001,1.09998-2.284,1.66003-4.881,1.66003-7.792s-.56006-5.49599-1.66003-7.754c-1.09998-2.259-2.84998-4.028-5.22998-5.308-2.35999-1.2799-5.46997-1.9198-9.33997-1.9198h-14.6001v67.1158h-11.63Z"
      />
      <g>
        <path
          fill="#5a595c"
          d="M29.93408,133.74463c8.0332-13.73438,24.87744-20.73145,42.49854-20.73145,33.4292,0,61.15723,24.35938,61.15723,62.97119,0,38.35254-28.24609,63.23047-61.41602,63.23047-16.84424,0-34.20654-7.51562-42.23975-21.25v8.0332c0,6.47852-5.18311,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.92041,5.44189,11.92041,11.92041v73.33691Zm40.42578,82.40674c21.76758,0,39.12988-16.58496,39.12988-40.42676,0-24.09961-17.3623-39.64795-39.12988-39.64795-19.43555,0-40.16699,13.73438-40.16699,39.64795,0,25.65527,18.6582,40.42676,40.16699,40.42676Z"
        />
        <path
          fill="#5a595c"
          d="M160.01904,56.00244c9.06982,0,16.32568,6.47852,16.32568,15.28906,0,8.81104-7.25586,15.28955-16.32568,15.28955-8.81055,0-16.32568-6.47852-16.32568-15.28955,0-8.81055,7.51514-15.28906,16.32568-15.28906Zm11.92041,70.22705c0-6.47852-5.18262-11.92041-11.92041-11.92041-6.47852,0-11.66113,5.44189-11.66113,11.92041v99.76855c0,6.47852,5.18262,11.9209,11.66113,11.9209,6.73779,0,11.92041-5.44238,11.92041-11.9209V126.22949Z"
        />
        <path
          fill="#5a595c"
          d="M217.28516,133.74463c7.25586-14.51221,24.61816-20.73145,37.83447-20.73145,30.06006,0,46.64502,19.69482,46.64502,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10303,0-34.20654,9.58838-34.20654,35.24268v54.67871c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v7.51514Z"
        />
        <path
          fill="#5a595c"
          d="M419.15381,60.40771c0-6.47852,5.18262-11.92041,11.92041-11.92041,6.47852,0,11.66113,5.44189,11.66113,11.92041V225.99805c0,6.47852-5.18262,11.9209-11.66113,11.9209-6.73779,0-11.92041-5.44238-11.92041-11.9209v-8.0332c-8.0332,13.73438-25.396,21.25-42.23975,21.25-33.16992,0-61.4165-24.87793-61.4165-63.23047,0-38.61182,27.72803-62.97119,61.15723-62.97119,17.62158,0,34.46582,6.99707,42.49902,20.73145V60.40771Zm-40.42578,75.66895c-21.76807,0-39.13037,15.54834-39.13037,39.64795,0,23.8418,17.3623,40.42676,39.13037,40.42676,21.5083,0,40.1665-14.77148,40.1665-40.42676,0-25.91357-20.73096-39.64795-40.1665-39.64795Z"
        />
        <path
          fill="#fff"
          d="M488.85938,284.30469c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V126.22949c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v8.29248c8.0332-13.73438,25.39551-21.50879,42.23975-21.50879,33.16992,0,60.63867,25.13672,60.63867,63.48877s-27.46875,62.71289-60.63867,62.71289c-16.84424,0-34.20654-6.73828-42.23975-20.73145v65.82129Zm39.90771-68.15332c21.76758,0,39.12988-15.29004,39.12988-39.38965s-17.3623-40.68506-39.12988-40.68506c-21.24951,0-39.64844,15.03027-39.64844,40.68506,0,25.91406,20.21289,39.38965,39.64844,39.38965Z"
        />
        <path
          fill="#fff"
          d="M630.34668,225.99805c0,6.47852-5.18262,11.9209-11.91992,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.91992,5.44189,11.91992,11.92041V225.99805Z"
        />
        <path
          fill="#fff"
          d="M749.29199,217.96484c-8.0332,13.73438-25.6543,21.25-42.49902,21.25-33.16992,0-61.41602-24.87793-61.41602-63.23047,0-38.61182,28.24609-62.97119,61.41602-62.97119,16.84473,0,34.46582,6.99707,42.49902,20.73145v-7.51514c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73828,0,11.9209,5.44189,11.9209,11.92041v99.76855c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-8.0332Zm-40.42578-81.88818c-21.76758,0-39.38867,15.54834-39.38867,39.64795,0,23.8418,17.62109,40.42676,39.38867,40.42676,21.50879,0,40.16699-14.77148,40.16699-40.42676,0-25.91357-20.73145-39.64795-40.16699-39.64795Z"
        />
        <path
          fill="#fff"
          d="M818.22363,133.74463c7.25586-14.51221,24.61816-20.73145,37.83398-20.73145,30.06055,0,46.64551,19.69482,46.64551,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10352,0-34.20605,9.58838-34.20605,35.24268v54.67871c0,6.47852-5.18359,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.7373,0,11.9209,5.44189,11.9209,11.92041v7.51514Z"
        />
        <path
          fill="#fff"
          d="M1029.16016,185.05371h-89.14355c2.85059,20.21387,18.6582,32.91113,39.12988,32.91113,14.51172,0,22.54492-3.62793,30.83789-12.69727,4.40527-4.92383,11.14258-6.47852,15.80762-3.11035,4.66406,3.62793,5.70117,10.625,2.07324,14.77148-11.66113,15.03027-29.80176,22.28613-49.49609,22.28613-34.46582,0-61.93457-25.13672-61.93457-63.49023s27.46875-62.71143,61.93457-62.71143,60.12012,24.35938,60.12012,62.71143c0,5.44238-4.40527,9.3291-9.3291,9.3291Zm-13.99316-18.39844c-2.85059-20.21289-17.3623-32.39258-36.79785-32.39258-19.9541,0-35.50195,12.17969-38.35254,32.39258h75.15039Z"
        />
      </g>
    </svg>
  );
};
